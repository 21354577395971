import React from "react";
import { Container, Row, Col, CardBody, Card } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
class ChiefVigilanceOfficer extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
          Chief Vigilance Officer - Indian Institute of Information Technology Kota
          </title>
          <meta
            name="description"
            content="Chief Vigilance Officer Indian Institute of Information Technology Kota"
          />
          <meta
            name="keywords"
            content="Chief Vigilance Officer, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota,Chief Vigilance Officer IIIT Kota,IIIT Kota Chief Vigilance Officer,Classes time IIIT Kota,Download Chief Vigilance Officer of IIIT Kota"
          />
          <meta name="author" content="Author name" />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-4">
                <Col lg="8">
                  <h2>
                    <b>Chief Vigilance Officer </b>
                  </h2>
                </Col>
              </Row>

              </Container>
              <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg={12} xs={12} className="text-justify">
                <h6>
                DO NOT PAY BRIBES. IF ANYBODY OF THIS OFFICE ASKS FOR BRIBE OR IF YOU HAVE ANY INFORMATION ON CORRUPTION IN THIS OFFICE OR IF YOU ARE A VICTIM OF CORRUPTION IN THIS OFFICE, YOU CAN COMPLAIN TO THE HEAD OF THIS DEPARTMENT, OR THE CHIEF VIGILANCE OFFICER / THE SUPERINTENDENT OF POLICE, CENTRAL BUREAU OF INVESTIGATION AND THE SECRETARY, CENTRAL VIGILANCE COMMISSION".
                <br/> <br/> 
                All complaints to the Central Vigilance Commission may be made in terms of its 'Complaint Handling Policy' which is available in public domain on the Commission's website i.e., www.cvc.gov.in. Complaints can also be lodged online on Commission's website.
                </h6>
                 </Col>
              </Row>
            </Container>
            <Container>
              <Row className="row-grid justify-content-center">

                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0 ">
                    <CardBody className=" justify-content-center">
                      <h5>
                      Head of the Institution 
                      </h5>
                  
                      <h5 className="text-primary ">
                            <b>Prof. N. P. Padhy</b>
                          </h5>
                          <div className="px-2 pb-2">
                            Director
                            <br />
                            Prabha Bhawan, <br />
                            MNIT Campus, <br />
                            JLN Marg, Jaipur-302017 
                          </div>

                          <h6>
                            <a href="mailto:satyanarayan@iiitkota.ac.in">
                              <i className="fa fa-envelope px-2" />
                              director@mnit.ac.in
                            </a>
                          </h6>

                          <h6>
                            <a href="tel:+91-141-2529087">
                              <i className="fas fa-phone-square-alt px-2" />
                              +91-141-2529087, <br /> &nbsp; &nbsp;&nbsp; &nbsp; +91-141-2529029
                            </a>
                          </h6>
     
                    </CardBody>
                  </Card>
                </Col>

                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0 ">
                    <CardBody className=" justify-content-center">
                      <h5>
                      Chief Vigilance Officer (I/C)
                      </h5>
                      <h5 className="text-primary ">
                            <b>Prof. Vijay Janyani</b>
                          </h5>
                          <div className="px-2 pb-2">
                          Professor <br/>
                          Dept. of Electronics and Communication Engg., <br/>
                          MNIT Campus, <br/>
                          JLN Marg, <br/>Jaipur-302017 <br/>
                           </div>

                          <h6>
                            <a href="mailto:satyanarayan@iiitkota.ac.in">
                              <i className="fa fa-envelope px-2" />
                              cvo@mnit.ac.in
                            </a>
                          </h6>

                          {/* <h6>
                            <a href="tel:+91-141-2529087">
                              <i className="fas fa-phone-square-alt px-2" />
                              +91-141-2529087, <br /> &nbsp; &nbsp;&nbsp; &nbsp; +91-141-2529029
                            </a>
                          </h6> */}
                    </CardBody>
                  </Card>
                </Col>

              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default ChiefVigilanceOfficer;
