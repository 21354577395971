/* eslint-disable */
import React from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import axios from "axios";
import Helmet from "react-helmet";

class Announcement extends React.Component {
  state = { announcement: [] };

  async componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    const announcementAll = await axios.get(
      "https://admin-panel-z73v.onrender.com/announcements"
    );
    const sortedAnnouncement = announcementAll.data.reverse();
    await this.setState({ announcement: sortedAnnouncement });
    // console.log(this.state.announcement);
  }

  render() {
    return (
      <>
        <Helmet>
          <title>
            Announcement - Indian Institute of Information Technology Kota
          </title>
          <meta
            name="description"
            content="Announcement Indian Institute of Information Technology Kota"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>Announcement</b>
                  </h2>
                </Col>
              </Row>

              {this.state.announcement
                ? this.state?.announcement?.map((ele) => (
                    <Row className="justify-content-center text mb-5">
                      <Col lg="12">
                        <Row className="row-grid justify-content-center">
                          <Col lg="12" id={ele?.title}>
                            <Card className="card-lift--hover shadow border-0">
                              <CardBody>
                                <Row>
                                  <Col lg={12} xs={12}>
                                    <h4>
                                      <b>{ele.title}</b>
                                    </h4>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    lg={12}
                                    xs={12}
                                    className="text-justify mt-2"
                                    style={{ whiteSpace: "pre-wrap" }}
                                  >
                                    <h6 style={{textAlign:"justify"}}>{ele.description}</h6>
                                    <br />
                                    {ele.link != "" && (
                                      <h6>
                                        <a
                                          href={ele.link}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {" "}
                                          Click here for reference{" "}
                                        </a>
                                      </h6>
                                    )}
                                    {ele.date && (
                                      <div className="small-text text-right">
                                        - Posted on {ele?.date}
                                      </div>
                                    )}
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ))
                : null}
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Announcement;
