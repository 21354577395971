import React from "react";
import { Container, Row, Col, Table } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
import axios from "axios";
import {Link} from "react-router-dom";

class StudentsSection extends React.Component {
  state = { List: [] };
  async componentDidMount() {
    console.log(this.props.match.params.year);
    const data = await axios.get(
      "https://admin-panel-z73v.onrender.com/batch/" + this.props?.match?.params?.year
    );
    const filteredData = data.data
      .map((ele) => [ele.name, ele.id])
      .sort((a, b) => {
        return a[1] - b[1];
      });
    console.log(filteredData);
    this.setState({ List: filteredData });

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Batch {this.props.match.params.year} - Indian Institute of
            Information Technology Kota
          </title>
          <meta name="description" content="IIIT Kota Students Page" />
          <meta
            name="keywords"
            content="Students IIIT Kota, IIIT Kota Students, Indian Institute of Information Technology Kota Students"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2 className="display-3">
                    {this.props.match.params.year} Batch
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col lg={12} xs={12} className="mt-3 mb-5">
                  <Table bordered>
                    <h6 className="only-padding">
                      <thead>
                        <tr>
                          <th style={{ width: "101px" }}>S.No</th>
                          <th style={{ width: "303px" }}>ID</th>
                          <th style={{ width: "606px" }}>Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.List
                          ? this.state.List.map((ele, i) => (
                              <tr>
                                <th>{i + 1}.</th>
                                <td>
                                  <Link to={"/student/" + ele[1]}>
                                    {ele[1].toUpperCase()}
                                  </Link>
                                </td>
                                <td>{ele[0].toUpperCase()}</td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </h6>
                  </Table>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default StudentsSection;
