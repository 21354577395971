import React from "react";
import { Container, Row, Col, Table } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import "assets/css/style.css";
import Helmet from "react-helmet";
class BnWCommitee extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Building and Works Commitee - Indian Institute of Information
            Technology, Kota
          </title>
          <meta
            name="description"
            content="Building and Works Commitee - Indian Institute of Information Technology, Kota"
          />
          <meta
            name="keywords"
            content="Building and Works Commitee iiit kota, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 0 0 0 0" />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center">
                <Col lg="8">
                  <h2>
                    <b>Building and Works Commitee</b>
                  </h2>
                </Col>
              </Row>

              <Row>
                <Col lg={12} xs={12} className="mt-5 mb-5">
                  <Table bordered>
                    <h6 className="only-padding">
                      <thead>
                        <tr>
                          <th style={{ width: "101px" }}>S.No</th>
                          <th style={{ width: "707px" }}>Name</th>
                          <th style={{ width: "202px" }}>Responsibility</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1.</th>
                          <td>
                            <b>Prof. N. P. Padhy</b>
                            <br />
                            Director, IIIT Kota (Ex-Officio)
                          </td>
                          <td>Chairperson</td>
                        </tr>
                        <tr>
                          <th scope="row">2.</th>
                          <td>
                            <b>Prof. Mahender Choudhary</b>
                            <br />
                            HoD , Department of Civil Enggineering, MNIT Jaipur
                            (Ex-Officio)
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th scope="row">3.</th>
                          <td>
                            <b> Shri D.P. Tanwar</b>
                            <br />
                           Head of Department of Civil Engineering, Govt. Polytechnic College, Kota
                          </td>
                          <td>Member</td>
                        </tr>
                        {/* <tr>
                          <th scope="row">4.</th>
                          <td>
                            <b> Shri Narendra Sharma</b>
                            <br />
                            M/S Vakrangee Software Ltd., Mumbai
                          </td>
                          <td>Member</td>
                        </tr> */}
                        <tr>
                          <th scope="row">4.</th>
                          <td>
                            <b>Shri Narendra Sharma </b>
                            <br />
                            Vakrangee Limited (Ex-Officio)
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th scope="row">5.</th>
                          <td>
                            <b>Prof. Sanjay Mathur </b>
                            <br />
                            Dean (Planning and Development) , MNIT Jaipur
                            (Ex-Officio)
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th scope="row">6.</th>
                          <td>
                            <b>Prof. Vineet Sahula</b>
                            <br />
                            Dean Digital Infrastructure and Services
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th scope="row">7.</th>
                          <td>
                            <b>Prof. Gunwant Sharma </b>
                            <br />
                            Department of Civil Engineering, MNIT Jaipur
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th scope="row">8.</th>
                          <td>
                            <b>Zonal Chief Engineer </b>
                            <br />
                            JVNL, Kota (Ex-Officio)
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th scope="row">9.</th>
                          <td>
                            <b>Cheif Engineer (Building)</b>
                            <br />
                            PWD, Jaipur (Ex-Officio)
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th scope="row">10.</th>
                          <td>
                            <b>Prof. K. K. Sharma</b>
                            <br />
                            Coordinator IIIT Kota (Ex-Officio)
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th scope="row">11.</th>
                          <td>
                            <b>Shri Mukund Joshi</b>
                            <br />
                            Retd. Special Director General, CPWD
                          </td>
                          <td>Member</td>
                        </tr>

                        <tr>
                          <th scope="row">12.</th>
                          <td>
                            <b>Prof. Tarush Chandra</b>
                            <br />
                            Department of Archietecture and Planning, MNIT
                            Jaipur
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th scope="row">13.</th>
                          <td>
                            <b>Prof. Rohit Bhakar</b>
                            <br />
                            Registrar , IIIT KOTA (Ex-Officio)
                          </td>
                          <td>Member</td>
                        </tr>

                        {/* <tr>
                          <th scope="row">14.</th>
                          <td>Representative of Industry Partners - Vacant</td>
                          
                        </tr> */}
                      </tbody>
                    </h6>
                  </Table>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row className="justify-content-center text-center">
                <Col lg="8">
                  <h4>
                    <b>
                      Minutes of Meeting (MoM) of Building and Works Commitee
                    </b>
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col lg={12} xs={12} className="mt-5 mb-5">
                  <Table borderless>
                    <h6>
                      <thead>
                        <tr>
                        <th></th>
                          <th style={{ width: "808px" }}>
                            Minutes of Meeting (MoM)
                          </th>
                          <th style={{ width: "202px" }}>Download</th>
                        </tr>
                      </thead>
                      <tbody>

                        <tr className="body_tr">
                        <td><ul><li></li></ul></td>
                          <td>
                            To download the MoM of 5th Building and Works
                            Commitee Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/Building_works_comitee/5BWC_MoM.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>

                        <tr className="body_tr">
                        <td><ul><li></li></ul></td>
                          <td>
                            To download the MoM of 4th Building and Works
                            Commitee Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/4BWC_MoM.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>
                        <tr className="body_tr">
                        <td><ul><li></li></ul></td>
                          <td>
                            To download the MoM of 3rd Building and Works
                            Commitee Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/3BWC_MoM.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>
                        <tr className="body_tr">
                        <td><ul><li></li></ul></td>
                          <td>
                            To download the MoM of 2nd Building and Works
                            Commitee Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/2BWC_MoM.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>
                        <tr className="body_tr">
                        <td><ul><li></li></ul></td>
                          <td>
                            To download the MoM of 1st Building and Works
                            Commitee Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/1BWC_MoM.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </h6>
                  </Table>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default BnWCommitee;
