import React from "react";
import { Container, Row, Col, Table } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import "assets/css/style.css";
import Helmet from "react-helmet";
class FinanceCommitee extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Finance Commitee - Indian Institute of Information Technology, Kota
          </title>
          <meta
            name="description"
            content=" Finance Commitee - Indian Institute of Information Technology, Kota"
          />
          <meta
            name="keywords"
            content=" Finance Commitee iiit kota,  Finance Commitee mnit, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 0 0 0 0" />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center">
                <Col lg="8">
                  <h2>
                    <b>Finance Commitee</b>
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col lg={12} xs={12} className="mt-5 mb-5">
                  <Table bordered>
                    <h6 className="only-padding">
                      <thead>
                        <tr>
                          <th style={{ width: "101px" }}>S.No</th>
                          <th style={{ width: "707px" }}>Name</th>
                          <th style={{ width: "202px" }}>Responsibility</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>1.</th>
                          <td>
                            <b>Lt. Gen. AK Bhatt (retd.)</b>
                            <br />
                            Former Military Secretary, 
                            <br />
                            PVSM UYSM AVSM SM VSM (Indian Army),
                            <br />
                            Former DGMO, MS & GOC 15 Corps,
                            <br />
                            Director General, Indian Space Association.
                          </td>
                          <td>Chairperson</td>
                        </tr>
                        <tr>
                          <th scope="row">2.</th>
                          <td>
                            <b>Narayan Singh Bisht</b>
                            <br />
                            Finance Bureau, Ministry of Education (MOE)
                          </td>
                          <td>Deputy Secretary</td>
                        </tr>
                        <tr>
                          <th scope="row">3.</th>
                          <td>
                            <b> Prof. N. P. Padhy</b>
                            <br />
                            Director, IIIT Kota (Ex-Officio)
                          </td>
                          <td>Member</td>
                        </tr>

                        <tr>
                          <th scope="row">4.</th>
                          <td>
                            <b>Financial Advisor</b>
                            <br />
                            RTU Kota, Govt. of Rajasthan (Ex-Officio)
                          </td>
                          <td>Member</td>
                        </tr>
                        {/* <tr>
                          <th scope="row">5.</th>
                          <td>
                            <b>Shri Narendra Sharma</b>
                            <br />
                            Vakrangee Software Limited
                            <br />
                            Representative of Industry Partners
                          </td>
                          <td>Member</td>
                        </tr> */}
                        <tr>
                          <th scope="row">5.</th>
                          <td>
                            <b>Shri Shashwat Kulshreshtha</b>
                            <br />
                            Representative of Industry Partners
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th scope="row">6.</th>
                          <td>
                            <b>Prof. Rohit Bhakar</b>
                            <br />
                            Registrar, IIIT Kota (Ex-Officio)
                          </td>
                          <td>Member Secretary</td>
                        </tr>
                      </tbody>
                    </h6>
                  </Table>
                </Col>
              </Row>
              <Row>
                <Col lg={12} xs={12} className="mt-5 mb-5">
                  <Table borderless>
                    <h6>
                      <thead>
                        <tr>
                          <th></th>
                          <th style={{ width: "808px" }}>
                            Minutes of Meeting (MoM)
                          </th>
                          <th style={{ width: "202px" }}>Download</th>
                        </tr>
                      </thead>
                      <tbody>

                      <tr className="body_tr">
                      <td><ul><li></li></ul></td>
                          <td>
                            To download the MoM of 10th Finance Commitee Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/financecomitee/Signed MoM of 10th FC IIIT Kota.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>

                      <tr className="body_tr">
                      <td><ul><li></li></ul></td>
                          <td>
                            To download the MoM of 9th Finance Commitee Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/financecomitee/MoM of 9th FC IIIT Kota.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>

                      <tr className="body_tr">
                      <td><ul><li></li></ul></td>
                          <td>
                            To download the MoM of 8th Finance Committee Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/financecomitee/8th%20FC%20MoM.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>
                      <tr className="body_tr">
                      <td><ul><li></li></ul></td>
                          <td>
                            To download the MoM of 7th Finance Commitee Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/7FC%20MoM.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>
                        <tr className="body_tr">
                        <td><ul><li></li></ul></td>
                          <td>
                            To download the MoM of 6th Finance Commitee Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/6th%20FC%20MoM.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>
                        <tr className="body_tr">
                        <td><ul><li></li></ul></td>
                          <td>
                            To download the MoM of 5th Finance Commitee Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/MoM%205FC.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>
                        <tr className="body_tr">
                        <td><ul><li></li></ul></td>
                          <td>
                            To download the MoM of 4th Finance Commitee Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/MoM%204FC.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>
                        <tr className="body_tr">
                        <td><ul><li></li></ul></td>
                          <td>
                            To download the MoM of 3rd Finance Commitee Meeting
                          </td>
                          <td>
                            <a
                              href="https://drive.google.com/file/d/12ovtg6pX_fRvirAYqMjHmIdFyshF0TJ0/view?usp=sharing"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>
                        <tr className="body_tr">
                        <td><ul><li></li></ul></td>
                          <td>
                            To download the MoM of 2nd Finance Commitee Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/financecomitee/2nd%20FC%20MoM.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>
                        <tr className="body_tr">
                        <td><ul><li></li></ul></td>
                          <td>
                            To download the MoM of 1st Finance Commitee Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/financecomitee/1st%20FC%20MoM.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </h6>
                  </Table>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default FinanceCommitee;
