/* eslint-disable */
import React from "react";
import { Button, Card, CardBody, Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import "assets/css/style.css";
import axios from "axios";
import Helmet from "react-helmet";

class Faculty extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            Faculties - Indian Institute of Information Technology, Kota
          </title>
          <meta
            name="description"
            content="Faculties - Indian Institute of Information Technology, Kota"
          />
          <meta
            name="keywords"
            content="faculties iiit kota, cse faculty iiit kota, ece faculty iiit kota, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 0 0 0 0" />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center">
                <Col lg="8">
                  <h2>
                    <b>Deans</b>
                  </h2>
                </Col>
              </Row>

              <Card className="card-lift--hover mt-4 shadow border-0"> 
                  
                  <CardBody>


                  <a href="https://mnit.ac.in/dept_ece/profile?fid=QKs=">

                    <Card className="shadow mt-3">

                      <CardBody>
                        
                          
                          <Row>

                            <Col lg={4}>
                            <img
                                      className="faculty-img"
                                      height="300px"
                                      alt=""
                                      src={require("../../../assets/img/people/administrating_body/coordinator_new.jpeg")}
                                    />
                            </Col>

                            <Col lg={8}>
                            
                              
                            <div className="text-center">

                                <Row>
                                  <Col className="ml-3 mt-7">
                                  <h3><b>Prof. K. K. Sharma</b></h3>
                                  </Col>
                                </Row>  
                                <Row>

                                <Col className="ml-3"><h5><b>Dean (Training & Placement)</b></h5></Col>
                                </Row>   

                            </div>

                            </Col>

                          </Row>

                        </CardBody>

                      </Card>

                    </a>

                    {/*
                    <a href="https://mnit.ac.in/dept_civil/profile?fid=Qq0=">
                  
                      <Card className="shadow mt-3">

                            <CardBody>
                              
                              
                              <Row>

                                <Col lg={4}>
                                <img
                                          className="faculty-img"
                                          height="300px"
                                          alt=""
                                          src={require("../../../assets/img/people/administrating_body/prof. sanjay mathur.png")}
                                        />
                                </Col>

                                <Col lg={8}>
                                
                                  
                                <div className="text-center">

                                    <Row>
                                      <Col className="ml-3 mt-7">
                                      <h3><b>Prof. Sanjay Mathur</b></h3>
                                      </Col>
                                    </Row>  
                                    <Row>

                                    <Col className="ml-3">
                                    <h5><b>Dean (Planning & Development)</b></h5>
                                    <h5><b>Dean (Digital Infrastructure & Services)</b></h5>
                                    </Col>
                                    </Row>   

                                </div>

                                </Col>

                              </Row>

                            </CardBody>

                          </Card>

                        </a>

                        */}


                        <a href="https://mnit.ac.in/dept_chemical/profile?fid=RK2I">
                  
                          <Card className="shadow mt-3">

                            <CardBody>
                              
                              
                              <Row>

                                <Col lg={4}>
                                <img
                                          className="faculty-img"
                                          height="300px"
                                          alt=""
                                          src={require("../../../assets/img/people/administrating_body/prof. manish vashishtha.png")}
                                        />
                                </Col>

                                <Col lg={8}>
                                
                                  
                                <div className="text-center">

                                    <Row>
                                      <Col className="ml-3 mt-7">
                                      <h3><b>Prof. Manish Vashishtha</b></h3>
                                      </Col>
                                    </Row>  
                                    <Row>

                                    <Col className="ml-3">
                                    <h5><b>Dean (Research & Consultancy)</b></h5>
                                    <h5><b>Dean (Alumni & Industry Outreach)</b></h5>
                                    <h5><b>International Affairs and MoU activities</b></h5>
                                    </Col>
                                    </Row>   

                                </div>

                                </Col>

                              </Row>

                            </CardBody>

                          </Card>

                        </a>


                        <a href="https://mnit.ac.in/dept_mech/profile?fid=RKmF">
                  
                          <Card className="shadow mt-3">

                            <CardBody>
                              
                              
                              <Row>

                                <Col lg={4}>
                                <img
                                          className="faculty-img"
                                          height="300px"
                                          alt=""
                                          src={require("../../../assets/img/people/administrating_body/prof. harlal singh mali.png")}
                                        />
                                </Col>

                                <Col lg={8}>
                                
                                  
                                <div className="text-center">

                                    <Row>
                                      <Col className="ml-3 mt-7">
                                      <h3><b>Prof. Harlal Singh Mali</b></h3>
                                      </Col>
                                    </Row>  
                                    <Row>

                                    <Col className="ml-3">
                                    <h5><b>Dean (Student Welfare), MoE activities</b></h5>
                                    <h5><b>Dean (Wellness & Health)</b></h5>
                                    </Col>
                                    </Row>   

                                </div>

                                </Col>

                              </Row>

                            </CardBody>

                          </Card>

                        </a>



                        <a href="https://mnit.ac.in/dept_cse/profile?fid=RKSK">
                  
                          <Card className="shadow mt-3">

                            <CardBody>
                              
                              
                              <Row>

                                <Col lg={4}>
                                <img
                                          className="faculty-img"
                                          height="300px"
                                          alt=""
                                          src={require("../../../assets/img/people/administrating_body/Dr.-Pilli-Emmanuel-Shubhakar.webp")}
                                        />
                                </Col>

                                <Col lg={8}>
                                
                                  
                                <div className="text-center">

                                    <Row>
                                      <Col className="ml-3 mt-7">
                                      <h3><b>Dr. Pilli Emmanuel Shubhakar</b></h3>
                                      </Col>
                                    </Row>  
                                    <Row>

                                    <Col className="ml-3">
                                    <h5><b>Dean (Academic Affairs)</b></h5>
                                    <h5><b>Dean (Faculty Welfare)</b></h5>
                                    </Col>
                                    </Row>   

                                </div>

                                </Col>

                              </Row>

                            </CardBody>

                          </Card>

                        </a>


                    
                  </CardBody>

              </Card>
             
             {/*
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="d-flex justify-content-center ">

                 
    
                  
                  <Col lg="4 mt-5">
                      <Card className="card-lift--hover shadow border-0">
                        <a
                          target="_blank"
                          href="https://mnit.ac.in/dept_ece/profile?fid=QKs="
                        >
                          <CardBody>
                            <div className="mb-4 text-center">
                          
                              
                              <img
                                className="faculty-img"
                                height="300px"
                                alt=""
                                src={require("../../../assets/img/people/administrating_body/coordinator.png")}
                              />
                            </div>
                            <div className="text-center">
                              <h6 className="text-default ">
                                <b>Prof. K. K. Sharma</b>
                              </h6>
                            </div>
                          </CardBody>
                        </a>
                      </Card>
                    </Col>

                    <Col lg="4 mt-5">
                      <Card className="card-lift--hover shadow border-0">
                        <a
                          target="_blank"
                          href="https://mnit.ac.in/dept_civil/profile?fid=Qq0="
                        >
                          <CardBody>
                            <div className="mb-4 text-center">
                             
                              <img
                                className="faculty-img"
                                height="300px"
                                alt=""
                                src={require("../../../assets/img/people/administrating_body/prof. sanjay mathur.png")}
                              />
                            </div>
                            <div className="text-center">
                              <h6 className="text-default ">
                                <b>Prof. Sanjay Mathur</b>
                              </h6>
                            </div>
                          </CardBody>
                        </a>
                      </Card>
                    </Col>

                    <Col lg="4 mt-5">
                      <Card className="card-lift--hover shadow border-0">
                        <a
                          target="_blank"
                          href="https://mnit.ac.in/dept_chemical/profile?fid=RK2I"
                        >
                          <CardBody>
                            <div className="mb-4 text-center">
                            
                              <img
                                className="faculty-img"
                                height="300px"
                                alt=""
                                src={require("../../../assets/img/people/administrating_body/prof. manish vashishtha.png")}
                              />
                            </div>
                            <div className="text-center">
                              <h6 className="text-default ">
                                <b>Prof. Manish Vashishtha</b>
                              </h6>
                            </div>
                          </CardBody>
                        </a>
                      </Card>
                    </Col>

                    <Col lg="4 mt-5">
                      <Card className="card-lift--hover shadow border-0">
                        <a
                          target="_blank"
                          href="https://mnit.ac.in/dept_mech/profile?fid=RKmF"
                        >
                          <CardBody>
                            <div className="mb-4 text-center">
                             
                              <img
                                className="faculty-img"
                                height="300px"
                                alt=""
                                src={require("../../../assets/img/people/administrating_body/prof. harlal singh mali.png")}
                              />
                            </div>
                            <div className="text-center">
                              <h6 className="text-default ">
                                <b>Prof. Harlal Singh Mali</b>
                              </h6>
                            </div>
                          </CardBody>
                        </a>
                      </Card>
                    </Col>

                    <Col lg="4 mt-5">
                      <Card className="card-lift--hover shadow border-0">
                        <a
                          target="_blank"
                          href="https://mnit.ac.in/dept_cse/profile?fid=RKSK"
                        >
                          <CardBody>
                            <div className="mb-4 text-center">
                             
                              <img
                                className="faculty-img"
                                height="300px"
                                alt=""
                                src={require("../../../assets/img/people/administrating_body/Dr.-Pilli-Emmanuel-Shubhakar.webp")}
                              />
                            </div>
                            <div className="text-center">
                              <h6 className="text-default ">
                                <b>Dr. Pilli Emmanuel Shubhakar</b>
                              </h6>
                            </div>
                          </CardBody>
                        </a>
                      </Card>
                    </Col>
                  

                  </Row>
                </Col>
              </Row>

              */}
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Faculty;
