import React, { useState, useEffect } from "react";
import Profile from "./sections/Profile";
import About from "./sections/About";
import Work from "./sections/Work";
import Skills from "./sections/Skills";
import Education from "./sections/Education";
import "./Student.css";
import Other from "./sections/Other";
import axios from "axios";
import resumeSchema from "resume-schema";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import { Container, Row, Col } from "reactstrap";
import NewFooter from "components/Footers/NewFooter.js";

export default function Student(props) {
  const [json, setJson] = useState();
  const [error, setError] = useState("");
  const [spin, setSpin] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        // console.log(props?.match?.params?.id);
        const URL = await axios.get(
          "https://admin-panel-z73v.onrender.com/student/" + props?.match?.params?.id
        );
        // console.log(URL.data[0].url);
        const jsonData = await axios.get(URL.data[0].url);
        // const jsonData = JSON.parse(jsonData1)
        setJson(jsonData.data);
        // setAbout(jsonData.basics?.summary);
        // console.log(jsonData.data);
        // document.title = jsonData.basics.name;
        // setSpin(true);
        if (jsonData && jsonData.data && jsonData.data.basics) {
          setSpin(true);
        } else setSpin(false);
        resumeSchema.validate(
          json,
          function (err, report) {
            if (err) {
              console.error("The resume was invalid:", err);
              // setSpin(false);
              setError(JSON.stringify(err));


            }
            // console.log("Resume validated successfully:", report);
          },
          function (err) {
            console.error("The resume was invalid:", err);
            setSpin(false);
          }
        );
      } catch (e) {
        console.error(" invalid:", e);
        setSpin(false);
      }
    })();
  }, [json, props.match.params.id]);

  return (
    <>
      <DemoNavbar />

      <div className="position-relative">
        {/* shape Hero */}
        <section className="section  section-shaped pb-200">
          <div className="shape shape-style-1 shape-default bg-gradient-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          {/* <Container>{ "2018KUCP1127 "}</Container> */}
          <Container
            className="shape-container d-flex align-items-center"
            style={
              ({ paddingTop: "50px !important" },
              { paddingBottom: "50px !important" })
            }
          >
            <div className="col px-0">
              <Row
                className="align-items-center justify-content-center"
                data-aos="fade-up"
              >
                <Col className="text-center" lg="8">
                  <p className="lead text-white" data-aos="fade-up">
                    <b>
                      <span style={{ fontSize: "25px" }}></span> <br />
                    </b>
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="0 0 0 0 0 0" />
            </svg>
          </div>
        </section>
        {/* 1st Hero Variation */}
      </div>

      {spin ? (
        <div className="container1">
          <aside className="aside">
            <div className="inner">
              {json.basics && <Profile profileData={json.basics} />}
            </div>
          </aside>
          <main className="main">
            <div className="inner">
              {json.basics.summary && (
                <About aboutData={json.basics?.summary} />
              )}
              {json.education && <Education educationData={json.education} />}
              {json.skills && <Skills skillsData={json.skills} />}
              {json.work && <Work workData={json.work} />}
              <Other data={json} />
            </div>
          </main>
        </div>
      ) : (
        <div>{error ? error : "Error 404 : user not found"}</div>
      )}
      <NewFooter />
    </>
  );
}
